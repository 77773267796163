<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Products</b-card-title>
        <b-card-text class="font-small-2">
          There is currently {{ browserData.length }} active products
        </b-card-text>
      </div>

    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="(browser,index) in browserData"
        :key="browser.browserImg"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar :src="browser.browserImg" />
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto product-name">
              {{ browser.name }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <div class="product-stats">
            <span class="font-weight-bold text-body-heading mr-1">{{ browser.basket }}</span>
            <span class="font-weight-bold text-body-heading mr-1">{{ browser.deliveries }}</span>
            <span class="font-weight-bold text-body-heading mr-1">{{ browser.number }}</span>
        </div>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BAvatar, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BAvatar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      chartData: [],
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      browserData: [
        {
          browserImg: 'https://hu-gadgets.trackey.org/web/images/trackey-img1.jpg',
          name: 'Trackey',
          basket: '54',
          deliveries: '12',
          number: '0'
        },
        {
          browserImg: 'https://cz.feizer.net/web/images/order-img1.jpg',
          name: 'Feizer',
          basket: '54',
          deliveries: '12',
          number: '0'
        },
        {
          browserImg: 'https://offertomix.com/wp-content/uploads/2020/10/heatpal-new-100x100.jpg',
          name: 'Heatpal',
          basket: '54',
          deliveries: '12',
          number: '0'
        },
        {
          browserImg: 'https://pl.newairshield.com/web/images/overview-list-img3.jpg',
          name: 'AirShield',
          basket: '54',
          deliveries: '12',
          number: '0'
        },
        {
          browserImg: 'https://offertomix.com/wp-content/uploads/2020/11/wander-main-100x100.jpg',
          name: 'Wanderheat',
          basket: '54',
          deliveries: '12',
          number: '0'
        },
        {
          browserImg: 'https://offertomix.com/wp-content/uploads/2020/10/main-ic-1-100x100.jpg',
          name: 'IonaMaster',
          basket: '54',
          deliveries: '12',
          number: '0'
        },
        {
          browserImg: 'https://offertomix.com/wp-content/uploads/2020/10/main-pic-100x100.jpg',
          name: 'PowerSaver',
          basket: '54',
          deliveries: '12',
          number: '0'
        },
        {
          browserImg: 'https://offertomix.com/wp-content/uploads/2020/10/1-1-100x100.jpg',
          name: 'Termomether',
          basket: '54',
          deliveries: '12',
          number: '0'
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  created() {
    for (let i = 0; i < this.browserData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    }
  },
}
</script>

<style lang='scss'>
.product-name{
    position: relative;
    top: 10px;
}
.product-stats{
    // background: rgb(255,0,0);
    // background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(9,9,121,1) 50%, rgba(0,0,0,1) 100%);
    margin-top: 5px;
    padding: 3px 0px 3px 15px;
    border-radius: 30px;
}
.product-stats span:last-child{
    color: #fff;
    background: #ced007;
    padding: 5px 10px 5px 10px;
    border-radius: 100%;
    line-height: 1px;
}

.product-stats span{
    color: #fff;
    background: #000;
    padding: 5px 10px 5px 10px;
    border-radius: 100%;
    line-height: 1px;
}
</style>