<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Countries</b-card-title>
        <b-card-text class="font-small-2">
          There is currently {{ activeCountries.length }} active countries
        </b-card-text>
      </div>
      
    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="(country,index) in activeCountries"
        :key="country.id"
        class="country-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar size="sm" :src="country.flag" />
          </b-media-aside>
          <b-media-body>
            <h5 class="align-self-center my-auto country-name">
              {{ country.name }}
            </h5>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
        <div class="country-stats">
            <span class="font-weight-bold text-body-heading mr-1 number-color-white">{{ country.basket }}</span>
            <span class="font-weight-bold text-body-heading mr-1 number-color-white">{{ country.deliveries }}</span>
        </div>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BAvatar, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {
    mapGetters
} from "vuex";
/* eslint-disable global-require */
export default {
  components: {
    BAvatar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      chartData: [],
      browserData: [
        {
          browserImg: require('@/assets/images/icons/google-chrome.png'),
          name: 'Google Chrome',
          usage: '54.4%',
        },
        {
          browserImg: require('@/assets/images/icons/mozila-firefox.png'),
          name: 'Mozila Firefox',
          usage: '6.1%',
        },
        {
          browserImg: require('@/assets/images/icons/apple-safari.png'),
          name: 'Apple Safari',
          usage: '14.6%',
        },
        {
          browserImg: require('@/assets/images/icons/internet-explorer.png'),
          name: 'Internet Explorer',
          usage: '4.2%',
        },
        {
          browserImg: require('@/assets/images/icons/opera.png'),
          name: 'Opera Mini',
          usage: '8.%',
        },
      ],
    }
  },

  computed: {
        ...mapGetters(["activeCountries"]),
    },

    mounted() {
        this.$store.dispatch("GET_COUNTRIES");
    },

}
</script>

<style lang="scss">
.country-states {
    margin-top: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease;
}
.country-states:hover {
    font-size: 20px;

    .country-name{
        font-size: 16px;
    }
}
.country-states:active {
    background: #fff;
}
.country-name{
    position: relative;
    top: 3px;
}

.country-stats{
//background-image: linear-gradient(to right, black , yellow);
    padding: 3px 0px 3px 15px;
    border-radius: 30px;
}

.country-stats span:first-child{
    color: #fff;
    background: #000;
    padding: 5px 10px 5px 10px;
    border-radius: 100%;
    line-height: 1px;
}

.country-stats span{
    color: #fff;
    background: #ced007;
    padding: 5px 10px 5px 10px;
    border-radius: 100%;
    line-height: 1px;
}
</style>